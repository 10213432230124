import React from "react"
import Layout from "../components/Layout"
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import SEO from "../components/SEO"

const CookiePolicy = ({ data, pageContext: { langKey, defaultLang, langTextMap } }) => {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes }
  } = data
  const footerNodes = nodes.filter(node => node.frontmatter.content === "Footer")
  const {frontmatter: { copyright, cookiePolicyText, privacyPolicyText }} = footerNodes[0]

  return (
    <Layout
      legalInfo="cookie-policy"
      langTextMap={langTextMap}
      langKey={langKey}
      defaultLang={defaultLang}
      copyright={copyright}
      cookiePolicyText={cookiePolicyText}
      privacyPolicyText={privacyPolicyText}  >
      <SEO
        lang={langKey}
        title="Cookie Policy"
        keywords={keywords}
        description={description}
        meta={[
          {name:"robots", content:"noindex, nofollow"}
        ]} />
      <section
        id="cookie-policy"
        className="d-flex privacy-policy">
          <Container className="mt-5 pt-5">
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
          </Container>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query CookiePolicyQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    markdownRemark(frontmatter: {content: {eq: "CookiePolicy"}}, fields: {langKey: {eq: $langKey}}) {
      html
      frontmatter {
        content
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    )
    {
      nodes {
        frontmatter {
          content
          cookiePolicyText
          copyright
          privacyPolicyText
          title
        }
      }
    }
  }
`;

export default CookiePolicy
